@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
  font-family: 'Inter';
}

body {
  width: 100vw;
  overflow-x: hidden;
  background: black;
}