:root {
  --clr-neutral-900: hsl(207, 19%, 9%);
  --clr-neutral-100: hsl(0, 0%, 100%);
  --clr-accent-400: hsla(261.176, 62%, 38%, 1);
  --clr-accent-800: rgb(60, 4, 165);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.card {
  color: var(--clr-neutral-100);
  background-size: cover;
  padding: 10rem 0 0;
  max-width: 25ch;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: transform 500ms ease;
}

.card:hover,
.card:focus-within {
  transform: scale(1.05);
}

.card-content {
  --padding: 1.5rem;
  padding: var(--padding);
  background: linear-gradient(
    hsl(0 0% 0% / 0),
    hsl(20 0% 0% / 0.3) 20%,
    hsl(0 0% 0% / 1)
  );
}

.card-title {
  position: relative;
  width: max-content;
  margin-bottom: 32px;
}

.card:hover .card-title::after,
.card:focus-within .card-title::after {
  transform: scaleX(1);
}

.card-title::after {
  content: "";
  position: absolute;
  height: 4px;
  width: calc(100% + var(--padding));
  left: calc(var(--padding) * -1);
  bottom: -10px;
  background: var(--clr-accent-400);
  transform-origin: left;
  transition: transform 500ms ease;
}

@media (hover) {
  .card-content {
    transform: translateY(55%);
    transition: transform 200ms ease;
  }

  .card-content > *:not(.card-title) {
    opacity: 0;
    transition: opacity 200ms linear;
  }

  .card:hover .card-content > *:not(.card-title),
  .card:focus-within .card-content > *:not(.card-title) {
    opacity: 1;
    transition-delay: 700ms;
  }

  .card:hover .card-content,
  .card:focus-within .card-content {
    transform: translateY(0);
    transition-delay: 500ms;
  }

  .card:focus-within .card-content {
    transition-duration: 0ms;
  }

  .card-title::after {
    transform: scaleX(0);
  }
}

.card-body {
  color: rgb(255, 255, 255/0.85);
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    transition-delay: 0ms !important;
  }
}
.view-more {
  color: #fff; /* Ensure text stands out */
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: transform 0.3s ease, color 0.3s ease;
}

.view-more:hover {
  color: #ffcc00; /* Change text color on hover */
}



